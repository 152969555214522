<script>
export default {
  name: 'kalio-accordion-row',
  props: {
    title: { type: String, default: null },
    isOpen: { type: Boolean, default: false },
    toggleEnabled: { type: Boolean, default: true },
    maxHeightClass: { type: String, default: 'max-h-64 overflow-y-scroll' },
    withDivider: { type: Boolean, default: true },
    animate: { type: Boolean, default: true },
  },
  emits: ['update:isOpen'],
  data() {
    return {
      open: this.isOpen,
    };
  },
  methods: {
    toggle() {
      if (!this.toggleEnabled) return;
      this.open = !this.open;
      this.$emit('update:isOpen', this.open);
    },
  },
};
</script>

<template>
  <div class="flex w-full flex-col">
    <div
      class="flex flex-row justify-between"
      :class="{'cursor-pointer': toggleEnabled, 'hover:opacity-75': !!title }"
      @click="toggle"
    >
      <span
        v-if="!!title"
        class="text-base"
      >
        {{ title }}
      </span>
      <slot
        v-else
        name="title"
      />
      <inline-svg
        v-if="toggleEnabled"
        :src="require('assets/images/icons/toggle-arrow.svg')"
        class="h-6 w-auto cursor-pointer fill-current p-2 text-blue-gray-300 transition"
        :class="open ? 'rotate-0' : 'rotate-180 '"
      />
    </div>
    <div
      class="overflow-scroll "
      :class="[
        open ? maxHeightClass : 'max-h-0',
        { 'transition-max-height ease-in-out': animate }
      ]"
    >
      <div
        v-if="withDivider"
        class="my-2 h-px w-full bg-blue-gray-500"
      />
      <slot />
    </div>
  </div>
</template>

<style scoped>

</style>
