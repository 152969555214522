<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config.js';

const { theme } = resolveConfig(tailwindConfig);

export default {
  name: 'cut-out-corner-card',
  props: {
    breakpoint: { type: String, default: null },
    cutSize: { type: String, default: '60px' },
    topRightColor: { type: String, default: 'pv-blue-700' },
    bottomLeftColor: { type: String, default: 'pv-blue-700' },
  },
  computed: {
    pseudoElementsStyles() {
      const classes = {
        '--size': this.cutSize,
        '--color-tr': theme.colors[this.topRightColor],
        '--color-bl': theme.colors[this.bottomLeftColor],
      };

      return classes;
    },
    cardClasses() {
      const breakpoints = {
        sm: 'sm:cut-out-corner-card',
        md: 'md:cut-out-corner-card',
        lg: 'lg:cut-out-corner-card',
        xl: 'xl:cut-out-corner-card',
      };

      return breakpoints[this.breakpoint] || 'cut-out-corner-card';
    },
  },
};
</script>

<template>
  <div
    class="relative"
    :class="cardClasses"
    :style="pseudoElementsStyles"
  >
    <slot />
  </div>
</template>
