/* eslint-disable no-undef */
const color = require('tailwindcss/colors');
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: [
    './app/**/*.{html,vue,js,erb}',
    './packs/**/app/**/*.{html,vue,js,erb}',
  ],
  safelist: [
    'bg-red-50',
    'bg-red-100',
    'bg-red-400',
    'bg-emerald-50',
    'bg-emerald-100',
    'bg-emerald-400',
    'bg-amber-50',
    'bg-amber-100',
    'bg-amber-400',
    'text-red-400',
    'text-emerald-400',
    'text-amber-400',
  ],
  theme: {
    borderWidth: {
      DEFAULT: '1px',
      0: '0',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px',
    },
    extend: {
      transitionProperty: {
        width: 'width',
        'max-height': 'max-height',
      },
      fontFamily: {
        sans: ['Rubik', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        amber: color.amber,
        'cool-gray': color.gray,
        banana: {
          50: '#FFFCF3',
          100: '#FEF9E7',
          200: '#FEEFC3',
          300: '#FDE59F',
          400: '#FBD257',
          500: '#F9BF0F',
          600: '#E0AC0E',
          700: '#957309',
          800: '#705607',
          900: '#4B3905',
        },
        'light-blue': {
          300: '#7DD3FC',
        },
        'pv-yellow': '#FCD34D',
        'pv-light-blue': '#3B82F6',
        // can't use nested color syntax due to cut-out-corner-card.vue looking for these keys in its script
        'pv-blue-700': '#1E293B',
        'pv-blue-800': '#131E2F',
        'pv-blue-900': '#0F172A',
        'pv-gray': '#CBD5E1',
        'pv-white': '#FFFFFF',
        'blue-gray': {
          50: '#F8FAFC',
          100: '#F1F5F9',
          200: '#E2E8F0',
          300: '#CBD5E1',
          400: '#94A3B8',
          500: '#64748B',
          600: '#475569',
          700: '#334155',
          800: '#1E293B',
          900: '#0F172A',
        },
        'blue-zodiac': {
          50: '#eff7ff',
          100: '#dcecfd',
          200: '#c1dffc',
          300: '#96ccfa',
          400: '#65aff5',
          500: '#4190f0',
          600: '#2b72e5',
          700: '#235ed2',
          800: '#224cab',
          900: '#214387',
          950: '#17274c',
        },
      },
      borderRadius: {
        '4xl': '2rem',
        '5xl': '2.5rem',
      },
      spacing: {
        26: '6.5rem',
        104: '26rem',
        112: '28rem',
        128: '32rem',
        144: '36rem',
        200: '50rem',
      },
      screens: {
        mobile: { max: '767px' },
      },
      outline: {
        default: '5px auto -webkit-focus-ring-color',
      },
    },
  },
  plugins: [
    require('@tailwindcss/forms')({ strategy: 'class' }),
    require('@tailwindcss/typography'),
  ],
};
